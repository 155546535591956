import React, { useContext } from 'react'
import { NextSeo } from 'next-seo'
import dynamic from 'next/dynamic'
import SearchResults from '../components/PropertySearchResults/SearchResults'
import SearchResultDateGroup from '../components/PropertySearchResults/SearchResultsDateGroup'
import {
  getProperties,
  getAuctionProperties,
  getMapProperties,
  getInspectionProperties,
} from '@lib/api-services/propertiesService'
import { OfficeSiteContext } from '@context/OfficeSiteContext'
import { UserContext } from '@context/UserContext'
import { stripQueryParam, UrlBuilder } from '@lib/helpers'
import {
  getListingSearchBreadcrumbs,
  getPropertyResultsMetaData,
} from '@lib/helpers/search'
import { Router } from '@routes'
import {
  listSearchPageType,
  domain,
  formSlugs,
  itemShowPerPage,
} from '@lib/variables'
import SuburbApiDataFromName from '@components/PropertySearchResults/SuburbProfile/helpers'
import {
  getCookie,
  setCookie,
  SEARCHPAGE_COOKIE,
  JWT_COOKIE,
  ACTIVEPIPE_COOKIE,
} from '@cookies'
import { submitCMSForm, getFormsFields } from '@lib/api-services/formsService'
import AdBanner from '@global/AdBanner'
import { toast } from 'react-toastify'
import { getAgentBySlug } from '@lib/api-services/agentsService'
import { Container } from '@styles/global.styled'
import Breadcrumbs from '@global/Breadcrumbs'
import SearchResultsForm from '@components/PropertySearchResults/SearchResultsForm'
import LoadmoreInfo from '@components/PropertySearchResults/LoadmoreInfo'
import OverviewHeading from '@components/PropertySearchResults/OverviewHeading'
import SearchResultsBar from '@components/PropertySearchResults/SearchResultsBar'
import SearchResultCount from '@components/PropertySearchResults/SearchResultsCount'
import ResultsBarMobileBottomMenu from '@components/PropertySearchResults/ResultsBarMobileBottomMenu'
import TimeableForm from '@components/PropertySearchResults/SearchResultsDateGroup/TimeableForm'
import { LISTING_TYPE, PROPERTY_RESULTS_PATH } from '@constants/property'

const SuburbProfile = dynamic(
  () =>
    import('../components/PropertySearchResults/SuburbProfile/SuburbProfile'),
  { ssr: false }
)

class ListingSearchPage extends React.Component {
  constructor(props) {
    super(props)

    // get exisiting form data from wagtail page
    let pageFormData =
      (props.pageData &&
        props.pageData.meta &&
        props.pageData.meta.form_data) ||
      {}

    /*
      For {this.state.searchParams.office}
      ------------------------------------
      When an office site is enabled, the search forces the office listing search
      logic which includes:
      1. Getting listings from a given office
      2. Getting listings from an associated office
      3. If the user searches a suburb/location field - pull in the full network results
    */

    this.state = {
      page: 1,
      propertiesData: this.props.propertiesData,
      loading: false,
      searchParams: {
        ...pageFormData,
        page: 1,
        order_by: props.queryParams?.order_by || '-created',
        start_date: null,
        end_date: null,
        office: this.props.officeContext?.currentSite?.officeId || null,
      },
    }
  }

  getProperties = async () => {
    const { userContext } = this.props
    const { searchParams } = this.state
    this.setState({ loading: true })

    let newProperties
    if (userContext && userContext.token)
      newProperties = await getProperties(searchParams, userContext.token)
    else newProperties = await getProperties(searchParams)

    this.setState((prev) => ({
      ...prev,
      propertiesData: newProperties,
      loading: false,
    }))
  }

  changeOrder = async (order) => {
    // get query from router and separate slug
    const { ['slug']: slug, ...query } = Router.router.query

    // return if already in that order
    if (query.order_by && query.order_by == order) return false

    // set loading state
    await this.setState({ loading: true })

    // set 'order_by' query to order
    query.order_by = order

    // set slug
    const url = `/${slug}/`

    // route to page & update the last search page cookie
    await Router.pushRoute(UrlBuilder(url, query))
    await setCookie(SEARCHPAGE_COOKIE, UrlBuilder(url, query))

    // reset loading state
    await this.setState({ loading: false })
  }

  changeView = async (view) => {
    // get query from router and separate slug
    const { ['slug']: slug, ...query } = Router.router.query

    // return if already in that order
    if (query.search_view && query.search_view === view) return false

    // set loading state
    this.setState((prev) => ({ ...prev, loading: true }))

    const listingType = this.props.pageData.meta.form_data.listing_type

    // set slug
    let url = `/${slug}/`
    if (view === 'auctions' || view === 'inspections') {
      if (view === 'inspections') {
        if (listingType === LISTING_TYPE.SALE) {
          url = PROPERTY_RESULTS_PATH.saleInspections
        } else {
          url = PROPERTY_RESULTS_PATH.leaseInspections
        }
      }
      if (view === 'auctions') {
        url = PROPERTY_RESULTS_PATH.auction
      }
    } else {
      if (
        [
          PROPERTY_RESULTS_PATH.saleInspections,
          PROPERTY_RESULTS_PATH.auction,
          PROPERTY_RESULTS_PATH.leaseInspections,
        ].includes(url)
      ) {
        delete query.date_from
        delete query.date_to

        if (listingType === LISTING_TYPE.SALE) {
          url = PROPERTY_RESULTS_PATH.sale
        } else {
          url = PROPERTY_RESULTS_PATH.lease
        }
      }
    }
    // set 'order_by' query to order
    query.search_view = view

    // handle exclusive preview filter if off market
    if (view === 'exclusivepreview') {
      query.offmarket_only = 1
      if (query.page) {
        delete query.page
      }
    } else delete query.offmarket_only

    // route to page & update the last search page cookie
    await Router.pushRoute(UrlBuilder(url, query))
    setCookie(SEARCHPAGE_COOKIE, UrlBuilder(url, query))

    // reset loading state
    this.setState((prev) => ({ ...prev, loading: false }))
  }

  filterDates = async (start, end) => {
    // get query from router and separate slug
    const { ['slug']: slug, ...query } = Router.router.query

    // set loading state
    await this.setState({ loading: true })

    // set filter criteria based on search type
    let date_gte = null
    let date_lte = null
    const { pageData } = this.props
    if (pageData.search_page_type == listSearchPageType.SEARCH_TYPE_AUCTION) {
      date_gte = 'date_from'
      date_lte = 'date_to'
    } else if (
      pageData.search_page_type == listSearchPageType.SEARCH_TYPE_INSPECTION
    ) {
      date_gte = 'date_from'
      date_lte = 'date_to'
    } else if (
      pageData.search_page_type == listSearchPageType.SEARCH_TYPE_SALEDATE
    ) {
      date_gte = 'salebySetDate__gte'
      date_lte = 'salebySetDate__lte'
    } else return false

    // set 'page' query to page number
    query[date_gte] = start
    query[date_lte] = end

    //reset page
    query.page = 1
    // set url
    const url = `/${slug}/`

    // route to page & update the last search page cookie
    await Router.pushRoute(UrlBuilder(url, query))
    await setCookie(SEARCHPAGE_COOKIE, UrlBuilder(url, query))

    // reset loading state
    const { searchParams } = this.state
    searchParams.start_date = start
    searchParams.end_date = end
    searchParams.page = 1
    await this.setState((prevState) => ({
      ...prevState,
      ...searchParams,
      loading: false,
    }))
  }

  componentDidUpdate(prevProps) {
    if (prevProps.propertiesData !== this.props.propertiesData) {
      this.setState((prev) => ({
        ...prev,
        propertiesData: this.props.propertiesData,
      }))
    }
  }

  onSubscribeSubmit = (values) => {
    this.setState({ loading: true })
    const { signUpForm } = this.props
    submitCMSForm(formSlugs.emailSignup, values).then((res) => {
      this.setState({ loading: false })
      if (res.ok) {
        toast.success(signUpForm.success_message)
      } else {
        toast.error(signUpForm.error_message)
      }
    })
  }

  render() {
    const { searchParams, propertiesData, page } = this.state
    const {
      pageData,
      suburbData,
      signUpForm,
      queryParams,
      officeContext,
      userContext,
      mapResults,
      agent,
    } = this.props

    // set search type for map detection
    const searchView = queryParams?.search_view

    // set page / per_page / meta description
    const metaData = getPropertyResultsMetaData(
      { ...queryParams, page },
      propertiesData.count,
      agent
    )

    let per_page = itemShowPerPage.ListingSearchPage
    if (searchView === 'map' || searchView === 'draw') per_page = 4

    // set search page type & get cms listing search pages
    let searchPages = null
    let ResultLayout = SearchResults
    let hideListingType = false
    if (
      pageData.search_page_type == listSearchPageType.SEARCH_TYPE_AUCTION ||
      pageData.search_page_type == listSearchPageType.SEARCH_TYPE_INSPECTION ||
      pageData.search_page_type == listSearchPageType.SEARCH_TYPE_SALEDATE
    ) {
      // set search results component to date group
      ResultLayout = SearchResultDateGroup
      hideListingType = true
    } else {
      // get search pages from either office or user context
      searchPages = queryParams.office
        ? officeContext.currentSite.searchSettings
        : userContext.searchPages
    }

    const SEO = {
      title: pageData.meta.meta_title
        ? pageData.meta.meta_title
        : metaData.searchString,
      openGraph: {
        title: pageData.meta.meta_title
          ? pageData.meta.meta_title
          : metaData.searchString,
      },
    }

    const ad_banner = pageData.ad_banner[0]
    const breadcrumbs = getListingSearchBreadcrumbs(queryParams)

    return (
      <>
        {[
          listSearchPageType.SEARCH_TYPE_AUCTION,
          listSearchPageType.SEARCH_TYPE_INSPECTION,
          listSearchPageType.SEARCH_TYPE_SALEDATE,
        ].includes(pageData.search_page_type) ? (
          <TimeableForm onViewChange={this.changeView} />
        ) : (
          <ResultsBarMobileBottomMenu onViewChange={this.changeView} />
        )}
        <Container style={{ minHeight: '100vh' }}>
          <Breadcrumbs breadcrumbs={breadcrumbs} />

          {searchView === 'map' || searchView === 'draw' ? (
            <NextSeo {...SEO} noindex={true} />
          ) : queryParams.agent_slug && propertiesData.count === 0 ? (
            <NextSeo {...SEO} noindex={true} nofollow={true} />
          ) : (
            <NextSeo {...SEO} />
          )}

          {ad_banner && <AdBanner {...ad_banner} />}

          <SearchResultsForm queryParams={{ ...queryParams }}>
            <OverviewHeading
              metaData={metaData}
              orderBy={searchParams.order_by}
              queryParams={queryParams}
              onOrderChange={this.changeOrder}
              pageType={pageData.search_page_type}
            />

            <SearchResultsBar
              onViewChange={this.changeView}
              view={searchView}
              pageType={pageData.search_page_type}
              listingType={pageData.meta.form_data.listing_type}
              disabled={
                metaData.total === 0 &&
                !['inspections', 'auctions'].includes(searchView)
              }
            />
          </SearchResultsForm>

          <SearchResultCount
            searchView={searchView}
            metaData={metaData}
            orderBy={searchParams.order_by}
            onOrderChange={this.changeOrder}
          />

          <ResultLayout
            items={propertiesData.results}
            metaData={metaData}
            page_type={pageData.search_page_type}
            searchView={searchView}
            mapResults={mapResults}
            queryParams={queryParams}
            filterDates={this.filterDates}
            totalItems={metaData.total}
          />

          {propertiesData?.results?.length &&
          metaData.end < metaData.total &&
          searchView !== 'map' ? (
            <LoadmoreInfo
              queryParams={{ ...queryParams, page }}
              metaData={metaData}
              searchPageType={pageData.search_page_type}
              onSuccess={(properties, newPage) => {
                const newReresults = propertiesData.results.concat(properties)
                this.setState((prev) => ({
                  ...prev,
                  page: newPage,
                  propertiesData: {
                    ...prev.propertiesData,
                    results: [...newReresults],
                  },
                }))
              }}
            />
          ) : null}
          {/* 
          Hide this block until the design is confirmed
        suburbData && <SuburbProfile {...suburbData} /> */}
          {/* ! Commenting out as BP want to revise the logic as per Erin request 2019-06-12 */}
          {/* <div className='o-wrapper block-component'>
          <SignUpBanner {...signUpForm} onSubmit={this.onSubscribeSubmit} />
        </div> */}
        </Container>
      </>
    )
  }
}

const OfficeContextListingSearchPage = (props) => {
  const officeContext = useContext(OfficeSiteContext)
  const userContext = useContext(UserContext)
  return (
    <ListingSearchPage
      officeContext={officeContext}
      userContext={userContext}
      {...props}
    />
  )
}

OfficeContextListingSearchPage.getInitialProps = async (ctx, pageData) => {
  let queryParams = stripQueryParam(ctx.query, 'slug')
  let propertiesData = null
  let mapResults = null
  let agent = null

  const token = getCookie(JWT_COOKIE, ctx)
  const apCookie = getCookie(ACTIVEPIPE_COOKIE, ctx)

  if (apCookie) queryParams.apContact = 1

  if (pageData.meta && pageData.meta.form_data) {
    queryParams = { ...pageData.meta.form_data, ...queryParams }
    if (pageData.meta.form_data.status == 'sold' && !queryParams.order_by) {
      queryParams.order_by = '-soldDetails_date'
    }
  }

  if (pageData.meta && pageData.meta.office_ancestor) {
    queryParams = { ...queryParams, office: pageData.meta.office_ancestor.id }
  }

  if (
    queryParams.search_view &&
    (queryParams.search_view === 'map' || queryParams.search_view === 'draw')
  ) {
    queryParams.per_page = 4
    mapResults = await getMapProperties(queryParams, token || false)
  } else {
    queryParams.per_page = itemShowPerPage.ListingSearchPage
  }

  if (queryParams.agent_slug) {
    agent = await getAgentBySlug(queryParams.agent_slug)
  }

  queryParams.page = 1

  switch (pageData.search_page_type) {
    case listSearchPageType.SEARCH_TYPE_PROPERTY: {
      propertiesData = await getProperties(queryParams, token || false)
      break
    }
    case listSearchPageType.SEARCH_TYPE_AUCTION: {
      queryParams.has_auction = 'true'
      queryParams.order_by = 'auction_date'
      propertiesData = await getAuctionProperties(queryParams, token || false)
      break
    }
    case listSearchPageType.SEARCH_TYPE_SALEDATE: {
      queryParams.has_salebySetDate = 'true'
      queryParams.order_by = 'salebySetDate'
      propertiesData = await getProperties(queryParams, token || false)
      break
    }
    case listSearchPageType.SEARCH_TYPE_INSPECTION: {
      queryParams.ordering = 'inspections'
      propertiesData = await getInspectionProperties(
        { ...queryParams },
        token || false
      )
      propertiesData.results = propertiesData.results.map((it) => it.listing)
      break
    }
  }
  // const page = ctx.query.page || 1
  let suburbData
  if (queryParams.address_suburb) {
    suburbData = await SuburbApiDataFromName(
      queryParams.address_suburb.split(',')[0]
    )
  }
  let signUpForm = await getFormsFields(
    formSlugs.emailSignup,
    'u-5/12@tablet u-3/12@desktop u-4/12@wide',
    'c-sign-up-banner__submit u-4/12@desktop u-3/12@wide'
  )
  // We are setting a cookie with the latest search page so users can return
  setCookie(SEARCHPAGE_COOKIE, ctx.asPath)
  return {
    pageData,
    propertiesData,
    mapResults,
    page: 1,
    queryParams,
    suburbData,
    agent,
    signUpForm: {
      title: 'Buyer Guide',
      copy: 'Everything you need to know about buying at home with Barry Plant, in one place.',
      ...signUpForm,
    },
  }
}

OfficeContextListingSearchPage.getRSSFeedItems = (_, { propertiesData }) => {
  let rssItems = []
  propertiesData?.results?.length &&
    propertiesData.results.map((property) => {
      const createdDate = new Date(Date.parse(property.created))

      // The RSS Description is HTML containing
      // 1. The main image
      // 2. The headline
      // 3. The office details
      // 4. The description

      let rssDescription = ''
      if (
        property.images &&
        property.images.length &&
        property.images[0].image.image_320_240
      ) {
        rssDescription += `<a href="${domain}${property.get_absolute_url}"><img src="${property.images[0].image.image_320_240}" width="160" style="float:left;margin:4px;border:0px"/></a>`
      }

      rssDescription += `<p><b>${property.headline}</b>`

      if (property.office_details) {
        rssDescription += `<br><b>${property.office_details.title} - ${property.office_details.phone} </b>`
      }

      rssDescription += `<br>${property.description}</p>`

      rssItems.push({
        title: property.address_display_string,
        id: `${domain}${property.get_absolute_url}`,
        link: `${domain}${property.get_absolute_url}`,
        description: rssDescription,
        content: property.description,
        date: createdDate,
        image:
          property.images &&
          property.images.length &&
          property.images[0].image.image,
      })
    })

  return rssItems
}

export default OfficeContextListingSearchPage
